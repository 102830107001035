// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import cms from "./cms";
import dashboard from "./dashboard";
import news from "./news";
import events from "./events";
import users from "./users";
import projects from "./projects";
import members from "./members";
import maheshwariBhawans from "./maheshwariBhawans";
import communityPride from "./communityPride";
import trusts from "./trusts";
import districts from "./districts";
import designation from "./designation";
import jobFairs from "./jobFairs";
import offer from "./offer";
import pratibhaSamman from "./PratibhaSamman";
import eLearning from "./Elearning";
import districtAndStateLevelSangathanHead from "./districtAndStateLevelSangathanHead";
import photoGallery from "./PhotoGallery";
import industry from "./industry";
import companyCategory from "./companyCategory";
import ourSponsor from "./ourSponser";
import recruiter from "./recruiter";
import anchalAndStates from "./anchalAndStates";
import adminReducer from "./admin";
import admin from "./admin";
import educationReducer from "./educations";
import jobTitleReducer from "./jobTitles";
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  cms,
  dashboard,
  news,
  events,
  projects,
  users,
  maheshwariBhawans,
  communityPride,
  trusts,
  jobFairs,
  offer,
  pratibhaSamman,
  eLearning,
  districtAndStateLevelSangathanHead,
  photoGallery,
  members,
  districts,
  designation,
  industry,
  companyCategory,
  ourSponsor,
  recruiter,
  admin,
  anchalAndStates,
  adminReducer,
  educationReducer,
  jobTitleReducer,
});

export default rootReducer;
