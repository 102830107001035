import Amplify, { Auth, Storage } from "aws-amplify";
export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
      region: process.env.REACT_APP_BASE_REGION, // REQUIRED - Amazon Cognito Region
      //  userPoolId: "XX-XXXX-X_abcd1234", //OPTIONAL - Amazon Cognito User Pool ID
      //  userPoolWebClientId: "XX-XXXX-X_abcd1234", //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      //   customPrefix: {
      //     public: "public/",
      //     // protected: "myProtectedPrefix/",
      //     // private: "myPrivatePrefix/",
      //   },
      AWSS3: {
        bucket: process.env.REACT_APP_AWS_BUCKET, //REQUIRED -  Amazon S3 bucket name
        region: process.env.REACT_APP_BASE_REGION, //OPTIONAL -  Amazon service region
      },
    },
  });
};
