// **  Initial State
const initialState = {
  loading: true,
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CARDS':
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    default:
      return state
  }
}

export default dashboardReducer
