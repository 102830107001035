// **  Initial State
const initialState = {
  userData: {},
  tokenData: {},
  isAuthenticated: false,
  isLoading: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.payload.user,
        tokenData: action.payload.tokens,
        isSuperAdmin:
          action.payload.user.admin_type == "SUPERADMIN" ? true : false,
        isAuthenticated: true,
        isLoading: false,
      };
    case "PROFILE_UPDATE":
      return {
        ...state,
        userData: action.payload,
      };
    case "LOGOUT":
      return { ...state, userData: {}, isLoading: false };
    case "LOADING":
      return { ...state, userData: {}, isLoading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
