import moment from "moment";

// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  exports: [
    {
      columns: [
        {
          title: "Name",
          width: { wpx: 150 },
        },
        {
          title: "Email",
          width: { wpx: 150 },
        },
        {
          title: "Mobile Number",
          width: { wpx: 150 },
        },
        {
          title: "Date of Birth",
          width: { wpx: 150 },
        },
        {
          title: "Gender",
          width: { wpx: 150 },
        },

        {
          title: "Blood group",
          width: { wpx: 150 },
        },
        {
          title: "Marital Status",
          width: { wpx: 150 },
        },
        {
          title: "Anchal",
          width: { wpx: 150 },
        },
        {
          title: "Pradesh",
          width: { wpx: 150 },
        },
        {
          title: "District",
          width: { wpx: 150 },
        },
        {
          title: "City",
          width: { wpx: 150 },
        },
      ],
      data: [],
    },
  ],
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const generateDataExcel = (data) => {
  var result = [];
  data.map((value) => {
    result.push([
      { value: value.name },
      { value: value.email },
      { value: `${value.phone_code} ${value.mobile_number}` },
      { value: moment(value.dob).format("DD-MM-YYYY") },
      { value: value.gender },
      { value: !!value.blood_group ? value.blood_group : "N/A" },
      { value: capitalizeFirstLetter(value.marital_status) },
      { value: value?.district?.anchal },
      { value: value?.district?.pradesh },
      { value: value?.district?.name },
      { value: value.maheshwari_city },
    ]);
  });
  return result;
};

const tenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
      };
    case "GET_EXPORTED_USERS":
      return {
        ...state,
        exports: [
          {
            columns: state.exports[0].columns,
            data: generateDataExcel(action.payload.data),
          },
        ],
      };
    default:
      return state;
  }
};

export default tenderReducer;
