// **  Initial State
const initialState = {
  states: [],
  anchal: [],
};

const anchalsAndStates = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ANCHAL_LIST":
      return {
        ...state,
        anchal: action.payload?.data,
      };
    case "GET_PRADESH_LIST":
      return {
        ...state,
        pradesh: action.payload?.data,
      };
    default:
      return state;
  }
};

export default anchalsAndStates;
