// **  Initial State
const initialState = {
  data: [],
};
// const getDistricts = (data) => {
//   [...new Set(action.payload.data.map((item) => item.name))].map((item) => {
//     return { label: item };
//   });
// };

const districtReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DISTRICT":
      return {
        ...state,
        data: action.payload.data,
        districts: [
          ...new Set(action.payload.data.map((item) => item.name)),
        ].map((item) => {
          return { label: item, value: item };
        }),
        anchal: [
          ...new Set(action.payload.data.map((item) => item.anchal)),
        ].map((item) => {
          return { label: item, value: item };
        }),
        pradesh: [
          ...new Set(action.payload.data.map((item) => item.pradesh)),
        ].map((item) => {
          return { label: item, value: item };
        }),
      };
    default:
      return state;
  }
};

export default districtReducer;
