// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
};

const ourSponsorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_OUR_SPONSORS":
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
      };
    default:
      return state;
  }
};

export default ourSponsorReducer;
