// **  Initial State
const initialState = {
  loading: false,
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
}

const membershipPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTENT_MANAGEMENT_PAGE":
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
      }
    case "LOADING_MEMBERSHIP_PLAN":
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default membershipPlanReducer
