// **  Initial State
const initialState = {
  data: [],
}

const industryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INDUSTRIES':
      return {
        ...state,
        data: action.payload.data,
      }
    default:
      return state
  }
}

export default industryReducer
