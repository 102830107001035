// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_SUBADMIN":
      return {
        ...state,
      };

    case "GET_SUBADMIN_LIST":
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
      };
    case "GET_SUBADMIN_DETAILS":
      return {
        ...state,
        data: action.payload,
      };

    // case "GET_ADMINS":
    //   return {
    //     ...state,
    //     data: action.payload.data,
    //     totalPages: action.payload.totalPages,
    //     totalRecords: action.payload.totalResults,
    //     params: { limit: action.payload.limit, page: action.payload.page },
    //   };
    default:
      return state;
  }
};

export default adminReducer;
